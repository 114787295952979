.home__link {
    display: block;
    position: fixed;
    z-index: 10;
    top: 30px;
    left: 30px;
    width: 40px;
    height: 40px;
    padding: 5px;
}

@include orientation(landscape) {
    .home__link {
        top: 40px;
        left: 50px;
    }
}

@include up(992) {
    .home__link {
        top: 70px;
        left: 50px;
    }
}

/* clear fix */
.grid::after {
    content: "";
    display: block;
    clear: both;
}

/* ---- .grid__item ---- */
.grid__sizer,
.grid__item {
    width: 100%;
}

@include up(992) {
    .grid__sizer,
    .grid__item {
        width: 50%;
    }
}

.grid__item {
    float: left;
}

figure {
    position: relative;
    overflow: hidden;
}

img {
    display: block;
    width: 100%;
    height: auto;
}

.photo__figcaption {
    position: absolute;
    bottom: 0;
    padding: 10px 35px;
    padding-right: 25px;
    font-family: $font-alt;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.5) 100%);

    .photo__title {
        font-family: $font;
        font-weight: bold;
        font-size: 1.125rem;
        position: relative;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            left: -35px;
            top: -19px;
            width: 50px;
            height: 1px;
            background-color: var(--white);
        }
    }

    img {
        display: block;
        width: 40px;
        height: 40px;
        padding: 10px;
        position: relative;
        z-index: 3;
        cursor: pointer;
    }

    .photo__btnClose {
        position: absolute;
        right: 25px;
        z-index: 2;
        opacity: 0;
        transform: rotate(-135deg);
    }

    .photo__description {
        position: absolute;
        padding: 0 35px;
        padding-top: 60px;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(100%);
        background: linear-gradient(180deg, rgba(22, 22, 22, 0) 0%, rgba(22, 22, 22, 0.35) 28.85%, rgba(22, 22, 22, 0.65) 99.99%);
    }

    .photo__text {
        font-size: 14px;
        padding: 14px 0;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        line-height: 30px;

        .photo__lens,
        .photo__exifs {
            display: flex;
            align-items: center;
        }

        .photo__exifs {
            word-spacing: 15px;
            span {
                word-spacing: normal;
            }
        }

        img {
            padding: 0;
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }
}

@include orientation(landscape) {
    .photo__figcaption {
        padding: 10px 55px;
        padding-right: 45px;

        .photo__title {
            &::before {
                left: -55px;
            }
        }

        .photo__description {
            padding: 0 55px;
        }

        .photo__btnClose {
            right: 45px;
        }
    }
}

@include down(340) {
    .photo__text {
        font-size: 12px !important;
    }
}

@include up(992) {
    .photo__figcaption {
        padding: 26px 55px;
        padding-right: 25px;

        .photo__title {
            font-size: 1.5rem;

            &::before {
                top: -24px;
                left: -55px;
                width: 100px;
            }
        }

        .photo__description {
            padding: 0 55px;
            padding-top: 60px;
        }

        .photo__text {
            font-size: 1.125rem;
            padding: 30px 0;

            .photo__exifs {
                margin-top: 15px;
            }

            img {
                padding: 0;
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
        }
        .photo__btnClose {
            right: 25px;
        }
    }
}
