.loader {
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(#161616, 0.9);
    display: flex;
    justify-content: center;
    align-self: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    display: none;

    img {
        display: block;
        width: 150px;
    }
}
